import Experience from "../Experience.js";
import Environment from "./Environment.js";
import DebugHelpers from "./DebugHelpers.js";
import Ui from "../Ui/Ui.js";
import State from "../State.js";
import Time from "../Utils/Time.js";
import Particles from "./Particles.js";
import ParticlesSimulation from "./ParticlesSimulation.js";
import SolarSystem from "./SolarSystem.js";

export default class World {
    constructor() {
        this.experience = Experience.getInstance()
        this.ui = new Ui()
        this.time = Time.getInstance()

        this.camera = this.experience.camera;
        this.scene = this.experience.scene

        this.resources = this.experience.resources
        this.html = this.experience.html
        this.sound = this.experience.sound
        this.debug = this.experience.debug.panel

        // Wait for resources
        this.resources.on( 'ready', () => {
            this.state = new State()

            //this.startWithPreloader()
            this.start()
        } )
    }

    start() {
        //this.ui.hardRemovePreloader()

        this.time.reset()

        this.setupWorld()

        this.animationPipeline();
    }

    setupWorld() {
        // Setup
        //this.cube = new ExampleClass()
        this.particles = new Particles()
        this.particlesSimulation = new ParticlesSimulation()
        this.solarSystem = new SolarSystem()
        this.environment = new Environment()

        // Add debug helpers
        this.debugHelpers = new DebugHelpers()

        // Animation timeline
        this.animationPipeline();

        // Dispatch event
        window.dispatchEvent(new CustomEvent('3d-app:loaded'))
    }

    startWithPreloader() {
        this.ui.playButton.classList.add( "fade-in" );
        this.ui.playButton.addEventListener( 'click', () => {

            this.ui.playButton.classList.replace( "fade-in", "fade-out" );
            //this.sound.createSounds();

            setTimeout( () => {
                this.time.reset()

                // Setup
                this.setupWorld()

                // Remove preloader
                this.ui.preloader.classList.add( "preloaded" );
                setTimeout( () => {
                    this.ui.preloader.remove();
                    this.ui.playButton.remove();
                }, 2500 );
            }, 100 );
        }, { once: true } );
    }

    animationPipeline() {
        // if ( this.text )
        //     this.text.animateTextShow()

        if ( this.camera )
            this.camera.animateCameraPosition()
    }

    resize() {
        this.state?.resize()
        this.particles?.resize()
        this.particlesSimulation?.resize()
        this.solarSystem?.resize()
    }

    update( deltaTime ) {
        this.debugHelpers?.update( deltaTime )
        this.cube?.update( deltaTime )
        this.particles?.update( deltaTime )
        this.particlesSimulation?.update( deltaTime )
        this.solarSystem?.update( deltaTime )
    }
}
