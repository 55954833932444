import * as THREE from "three";

import Debug from "./Utils/Debug.js";
import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import World from "./World/World.js";
import Resources from "./Utils/Resources.js";
import Sound from "./Utils/Sound.js";
import Input from "./Utils/Input.js";

import sources from "./sources.js";
import gsap from "gsap";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import State from "./State.js";
import PostProcess from "./Utils/PostProcess.js";

import EventEmitter from "./Utils/EventEmitter.js";

export default class Experience extends EventEmitter {
	static _instance = null;

	static getInstance() {
		return Experience._instance || new Experience();
	}

	appLoaded = false;
	firstRender = false;

	constructor(_canvas) {
		super();
		// Singleton
		if (Experience._instance) {
			return Experience._instance;
		}
		Experience._instance = this;

		// Global access
		window.experience = this;

		window.addEventListener("3d-app:loaded", () => {
			this.appLoaded = true;
		});

		// Html Elements
		this.html = {};
		this.html.preloader = document.getElementById("preloader");
		this.html.playButton = document.getElementById("play-button");

		// Options
		this.canvas = _canvas;
		THREE.ColorManagement.enabled = false;

		if (!this.canvas) {
			console.warn("Missing 'Canvas' property");
			return;
		}

		this.setDefaultCode();

		// Start Loading Resources
		this.resources = new Resources(sources);

		this.init();
	}

	init() {
		// Setup
		this.debug = new Debug();
		this.sizes = new Sizes();
		this.time = new Time();
		this.input = new Input();
		this.scene = new THREE.Scene();
		this.camera = new Camera();
		this.renderer = new Renderer();
		this.state = new State();
		this.sound = new Sound();
		this.world = new World();
		this.postProcess = new PostProcess(this.renderer.instance);

		this.setListeners();
		this.trigger("classesReady");
	}

	postInit() {}

	resize() {
		this.camera.resize();
		this.world.resize();
		this.renderer.resize();
		this.postProcess.resize();
		this.debug.resize();
		//this.sound.resize()
	}

	update() {
		this.camera.update(this.time.delta);
		this.world.update(this.time.delta);

		if (this.state.postprocessing) {
			this.postProcess.update(this.time.delta);
		} else {
			this.renderer.update(this.time.delta);
		}

		if (this.debug.active) {
			this.debug.update(this.time.delta);
		}

		this.postUpdate();
	}

	postUpdate() {
		if (this.firstRender === true) {
			window.dispatchEvent(new CustomEvent("app:models-loaded"));
			this.firstRender = 2;
		}

		if (this.resources.loadedAll && this.appLoaded && this.firstRender === false) {
			this.firstRender = true;
		}
	}

	setListeners() {
		// Resize event
		this.sizes.on("resize", () => {
			this.resize();
		});

		// Time tick event
		this.time.on("tick", () => {
			this.update();
			this.debug?.stats?.update();
		});
	}

	setDefaultCode() {
		document.ondblclick = function (e) {
			e.preventDefault();
		};

		gsap.registerPlugin(MotionPathPlugin);

		this.isMobile();
	}

	isMobile() {
		let check = false;
		(function (a) {
			if (
				/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
					a
				) ||
				/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
					a.substr(0, 4)
				)
			)
				check = true;
		})(navigator.userAgent || navigator.vendor || window.opera);
		this.isMobile = check;
		return check;
	}

	destroy() {
		this.sizes.off("resize");
		this.time.off("tick");

		// Traverse the whole scene
		this.scene.traverse((child) => {
			// Test if it's a mesh
			if (child instanceof THREE.Mesh) {
				child.geometry.dispose();

				// Loop through the material properties
				for (const key in child.material) {
					const value = child.material[key];

					// Test if there is a dispose function
					if (value && typeof value.dispose === "function") {
						value.dispose();
					}
				}
			}
		});

		this.camera.controls.dispose();
		this.renderer.instance.dispose();

		if (this.debug.active) this.debug.ui.destroy();
	}
}
