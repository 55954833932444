<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M7 7L17 17" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M17 7V17H7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	name: "GlobalArrowDownRight"
};
</script>
